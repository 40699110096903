import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import { FooterAlternate1 } from "../components/footer"
import SEO from "../components/seo"

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Knowledge Centre" />

    <header className="header header--basic ">
        <div className="header__content">
            <div className="container">
                <div className="row">
                    <div className="header__inner col-md-9 col-lg-7 col-lg-offset-1">
                        <h1 className="header__title">Knowledge centre</h1>
                        <h3 className="header__caption">Search for the latest articles and video&#39;s on sales best practices.</h3>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <div className="landing landing--base">

        <div className="landing__item">
            <div className="container">
                <div className="landing__columns row">
                    <div className="col-xs-12 col-sm-6">
                        <div className="landing__image">
                            <img src="../media/svg/landing-552x580_knowledgecentre-challenges.svg" alt="" />
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-sm-offset-1">
                        <Link to="/knowledge-centre/challenges/" className="landing__copy">
                            <h2 className="landing__title">Challenges</h2>
                            <p>When buyers change how they buy, then sellers must also change how they sell.</p>
                            <button className="btn btn-link">Learn more</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>

        <div className="landing__item is-odd">
            <div className="container">
                <div className="landing__columns row">
                    <div className="col-xs-12 col-sm-6 col-sm-push-6">
                        <div className="landing__image">
                            <img src="../media/svg/landing-552x580_knowledgecentre-insights.svg" alt="" />
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-sm-pull-5">
                        <Link to="/knowledge-centre/insights/" className="landing__copy">
                            <h2 className="landing__title">Insights</h2>
                            <p>An evolving repository of hand selected content (both video and articles) that will highlight the need for urgent transformation in B2B sales.</p>
                            <button className="btn btn-link">Learn more</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>

        <div className="landing__item">
            <div className="container">
                <div className="landing__columns row">
                    <div className="col-xs-12 col-sm-6">
                        <div className="landing__image">
                            <img src="../media/svg/landing-552x580_knowledgecentre-library.svg" alt="" />
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-sm-offset-1">
                        <Link to="/knowledge-centre/library/" className="landing__copy">
                            <h2 className="landing__title">The library</h2>
                            <p>The most highly recommended books providing practical guidance to sales professionals.</p>
                            <button className="btn btn-link">Learn more</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>

        <div className="landing__item is-odd">
            <div className="container">
                <div className="landing__columns row">
                    <div className="col-xs-12 col-sm-6 col-sm-push-6">
                        <div className="landing__image">

                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-sm-pull-5">
                        <Link to="/press/" className="landing__copy">
                            <h2 className="landing__title">Recent press</h2>
                            <p></p>
                            <button className="btn btn-link">Learn more</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>

    </div>
    
    <FooterAlternate1 />

  </Layout>
)

export default IndexPage
